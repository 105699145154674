<template>
  <v-chip
    close
    outlined
    @click.stop
    @click:close="$emit('remove', true)">
    <v-combobox
      :ref="createRef"
      v-model="searchVal"
      :items="items"
      :placeholder="name"
      :search-input.sync="searchValSync"
      :style="{ width: textFieldSize }"
      class="pt-0 mt-0 search-filter__dropdown"
      color="black"
      dense
      flat
      hide-details
      persistent-placeholder
      solo
      @change="onUpdate" />
    <span
      ref="contentWidth"
      class="content-width fs-42">{{ searchVal }}</span>
  </v-chip>
</template>
<script>
export default {
  name: 'SearchFilterInputDropdown',
  props: {
    name: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    itemValue: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    alias: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    searchVal: '',
    searchValSync: '',
    textFieldSize: '345px',
  }),
  computed: {
    createRef() {
      return this.name.concat('-', this.index);
    },
  },
  watch: {
    itemValue: {
      async handler() {
        if (this.autofocus) {
          await this.$nextTick();
          this.searchValSync = this.itemValue;
          await this.$nextTick();
          this.$refs[this.createRef].focus();
          await this.$nextTick();
          this.$refs[this.createRef].activateMenu();
          return;
        }
        this.searchVal = this.itemValue;
      },
      immediate: true,
    },
  },
  methods: {
    onUpdate() {
      this.$emit('update', {
        value: this.searchVal, final: true,
      });

      this.$nextTick(() => {
        let ref = this.$refs.contentWidth;
        if (ref) {
          let width = ref.clientWidth;
          this.textFieldSize = (width ? width + 50 : 345) + 'px';
        } else {
          this.textFieldSize = '345px';
        }
      });
    },
  },
};
</script>